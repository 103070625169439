import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import Container from 'components/shared/Container';
import Navigation from 'components/Footer/Navigation';
import { HeadingThree, Text, TextLarge } from 'components/shared/Typography';
import Logo from 'components/shared/Logo';
import Call from 'components/shared/Call';
import iconFb from 'assets/icons/fb.svg';

const Wrapper = styled.footer`
  position: relative;
  margin: 0;
  padding: 50px 0 0 0;
  background-color: ${({ theme }) => theme.colors.blue};

  @media screen and (max-width: 992px) {
    padding: 20px 0 0 0;
  }
`;

const Bar = styled.div`
  margin: 50px 0 0 0;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 992px) {
    margin: 20px 0 0 0;
    padding: 20px 0;
    flex-direction: column;
  }
`;

const typographyStyles = css`
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.light};

  @media screen and (max-width: 992px) {
    text-align: center;
  }
`;

const Copy = styled.div`
  ${typographyStyles};
`;

const CopyLink = styled(Link)`
  ${typographyStyles};
  display: inline-block;
  margin: 0 0 0 40px;

  @media screen and (max-width: 992px) {
    margin: 0 0 0 20px;
  }
`;

const Author = styled.p`
  ${typographyStyles};
  & a {
    ${typographyStyles};
    font-weight: 600;
    white-space: nowrap;
  }

  @media screen and (max-width: 992px) {
    margin: 10px 0 0 0;
  }
`;

const Textblock = styled(Text)`
  margin: 15px 0;
  color: ${({ theme }) => theme.colors.lightOpacity};
  & a {
    color: ${({ theme }) => theme.colors.lightOpacity};
    transition: color 300ms ease-in-out;
    &:hover {
      color: ${({ theme }) => theme.colors.light};
    }
  }
`;

const CtaBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: calc(100% - 400px);
  margin: -70px 0 0 auto;
  padding: 35px 50px;
  background-color: ${({ theme }) => theme.colors.greenVariant};

  @media screen and (max-width: 992px) {
    flex-direction: column;
    width: 100%;
    margin: 0;
    max-width: unset;
    padding: 20px 25px;
  }
`;

const CtaBoxTitle = styled(HeadingThree)`
  max-width: 360px;

  @media screen and (max-width: 992px) {
    margin: 0 0 10px 0;
    text-align: center;
  }
`;

const TopSection = styled.div`
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
  }
`;

const Content = styled.div`
  @media screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 auto 35px auto;

    & ${TextLarge}, & ${Textblock} {
      text-align: center;
    }

    & ${Textblock} {
      margin: 10px 0 0 0;
    }
  }
`;

const Badge = styled.a`
  position: fixed;
  bottom: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding: 16px;
  background: ${({ theme }) => theme.colors.greenPrimary};
  z-index: 999;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: ${({ theme }) => theme.colors.greenVariant};
  }
  & img {
    height: 24px;
    width: 24px;
  }

  @media screen and (min-width: 699px) {
    bottom: 24px;
    right: 24px;
  }

  @media screen and (min-width: 991px) {
    padding: 24px;
    bottom: 36px;
    right: 36px;
  }
`;

const links = [
  {
    url: '/o-firmie/',
    text: 'O firmie',
  },
  {
    url: '/uslugi/',
    text: 'Usługi',
  },
  {
    url: '/nasi-klienci/',
    text: 'Nasi klienci',
  },
  {
    url: '/galeria/',
    text: 'Galeria',
  },
  {
    url: '/kontakt/',
    text: 'Kontakt',
  },
];

const Footer = () => {
  const wpgraphql = useStaticQuery(graphql`
    query querySettingsFooter {
      wp {
        ustawieniaMotywu {
          ThemeSettings {
            footerContactContent
            footerContactEmail
            footerContactHeading
            footerCtaButtonPhone
            footerCtaButtonTitle
            footerCtaHeading
          }
        }
      }
    }
  `);

  const data = wpgraphql.wp.ustawieniaMotywu.ThemeSettings;

  return (
    <Wrapper>
      <Badge
        href="https://www.facebook.com/Merc-Des-Mechanika-Pojazdowa-DW-D%C4%85browscy-587421908015328/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={iconFb} alt="" />
      </Badge>
      <Container>
        <TopSection>
          <Content>
            <Logo isFooter />
            <TextLarge color="lightOpacity" weight={600}>
              {data.footerContactHeading}
            </TextLarge>
            <Textblock
              color="lightOpacity"
              dangerouslySetInnerHTML={{
                __html: data.footerContactContent,
              }}
            />
            <Textblock color="lightOpacity">
              <a href={`mailto:${data.footerContactEmail}`}>
                {data.footerContactEmail}
              </a>
            </Textblock>
          </Content>
          <CtaBox>
            <CtaBoxTitle color="white">{data.footerCtaHeading}</CtaBoxTitle>
            <Call
              light
              title={data.footerCtaButtonTitle}
              phone={data.footerCtaButtonPhone}
            />
          </CtaBox>
        </TopSection>
        <Navigation links={links} />
        <Bar>
          <Copy>
            Copyright &copy; 2021 - Top Flota Marcin Szymański
            <CopyLink to="/polityka-prywatnosci/">
              Polityka Prywatności
            </CopyLink>
          </Copy>
          <Author>
            Strona Internetowa zaprojektowana przez{' '}
            <a
              href="https://wzor.biz/"
              target="_blank"
              rel="dofollow noreferrer"
            >
              Agencja marketingowa Wzór
            </a>
          </Author>
        </Bar>
      </Container>
    </Wrapper>
  );
};

export default Footer;
