import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LinkItem = styled.li`
  position: relative;
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  margin: 20px 25px;
  cursor: pointer;
  transition: 0.3s;
  @media (hover: hover) {
    &:hover {
      &:after {
        transform: translateX(-50%) scaleX(1);
        ${({ theme }) => theme.mq.lg} {
          transform: scaleX(1);
        }
      }
    }
  }
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -10px;
    width: 80px;
    height: 2px;
    background-color: #fff;
    transition: 0.3s;
    transform: translateX(-50%) scaleX(0);
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 16px;
    &:after {
      left: 0;
      transform-origin: 0 50%;
      transform: scaleX(0);
      width: 50px;
    }
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
  }

  & ${LinkItem} {
    color: ${({ isNavStyled, theme }) =>
      isNavStyled ? `${theme.colors.blueVariant}` : '#fff'};
    &:after {
      background-color: ${({ isNavStyled, theme }) =>
        isNavStyled ? `${theme.colors.blueVariant}` : '#fff'};
    }

    @media screen and (max-width: 992px) {
      color: ${({ theme }) => theme.colors.greenVariant};
    }
  }
`;

const Links = ({ isNavStyled, close }) => {
  return (
    <>
      <List isNavStyled={isNavStyled}>
        <LinkItem onClick={close}>
          <StyledLink to="/o-firmie/">O firmie</StyledLink>
        </LinkItem>
        <LinkItem onClick={close}>
          <StyledLink to="/uslugi/">Usługi</StyledLink>
        </LinkItem>
        <LinkItem onClick={close}>
          <StyledLink to="/nasi-klienci/">Nasi klienci</StyledLink>
        </LinkItem>
        <LinkItem onClick={close}>
          <StyledLink to="/galeria/">Galeria</StyledLink>
        </LinkItem>
        <LinkItem onClick={close}>
          <StyledLink to="/kontakt/">Kontakt</StyledLink>
        </LinkItem>
      </List>
    </>
  );
};

Links.propTypes = {
  close: PropTypes.func,
  isNavStyled: PropTypes.bool,
};

Links.defaultProps = {
  close: () => null,
  isNavStyled: false,
};

export default Links;
