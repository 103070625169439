import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Icon from 'components/shared/Icon';
import Logo from 'components/shared/Logo';
import Container from 'components/shared/Container';
import menuIcon from 'assets/icons/menu.svg';
import Links from 'components/Navigation/Links';
import Sidenav from 'components/Navigation/Sidenav';
import useMedia from 'hooks/useMedia';
import Call from 'components/shared/Call';

const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  align-items: center;
  background-color: ${({ isNavStyled }) =>
    isNavStyled ? `#fff` : 'transparent'};
  height: ${({ theme }) => theme.navSize.mobile};
  z-index: 100;
  transform: ${({ navHidden, theme }) =>
    navHidden ? `translate3d(0, -${theme.navSize.mobile}, 0)` : 'none'};
  transition: transform ease-in-out 250ms, background-color ease-in-out 250ms;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  ${({ theme }) => theme.mq.lg} {
    height: ${({ theme }) => theme.navSize.desktop};
    transform: ${({ navHidden, theme }) =>
      navHidden ? `translate3d(0, -${theme.navSize.desktop}, 0)` : 'none'};
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LinksWrapper = styled.div`
  display: none;
  width: 100%;
  padding: 0 10px;
  ${({ theme }) => theme.mq.lg} {
    display: flex;
    justify-content: space-evenly;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  background: transparent;
  padding: 0;
  height: ${({ theme }) => theme.navSize.mobile};
  ${({ theme }) => theme.mq.lg} {
    height: ${({ theme }) => theme.navSize.desktop};
  }
  transition: opacity 300ms ease-in-out;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const MenuButton = styled.button`
  display: block;
  background: transparent;
  cursor: pointer;
  filter: ${({ isWhite }) => (isWhite ? 'brightness(1000)' : 'brightness(1)')};
  ${({ theme }) => theme.mq.lg} {
    display: none;
  }
`;

const Navigation = () => {
  const [isNavHidden, setIsNavHidden] = useState(false);
  const [isSidenavVisible, setIsSidenavVisible] = useState(false);
  const [isNavStyled, setIsNavStyled] = useState(false);

  let prevScroll = 0;

  const handleScroll = e => {
    const window = e.currentTarget;

    if (prevScroll > window.scrollY) {
      setIsNavHidden(false);
    } else if (window.scrollY >= 100) {
      setIsNavHidden(true);
    }
    prevScroll = window.scrollY;

    if (window.scrollY > 100) {
      setIsNavStyled(true);
    } else {
      setIsNavStyled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', e => handleScroll(e));

    return () => {
      window.removeEventListener('scroll', e => handleScroll(e));
    };
  }, []);

  const hideNav = () => {
    if (window.scrollY >= 100) setIsNavHidden(true);
  };

  const closeSidenav = () => {
    setIsSidenavVisible(false);
    setTimeout(hideNav, 800);
    document.body.style.overflow = 'auto';
  };

  const openSidenav = () => {
    setIsSidenavVisible(true);
    document.body.style.overflow = 'hidden';
  };

  const matchesLG = useMedia('lg');

  const wpgraphql = useStaticQuery(graphql`
    query querySettingsNav {
      wp {
        ustawieniaMotywu {
          ThemeSettings {
            navCtaPhone
            navCtaTitle
          }
        }
      }
    }
  `);

  const data = wpgraphql.wp.ustawieniaMotywu.ThemeSettings;

  return (
    <>
      {!matchesLG && (
        <Sidenav isVisible={isSidenavVisible} close={closeSidenav} />
      )}
      <Wrapper navHidden={isNavHidden} isNavStyled={isNavStyled}>
        <Container>
          <InnerWrapper>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            {matchesLG && (
              <>
                <LinksWrapper>
                  <Links
                    isNavStyled={isNavStyled}
                    close={() => setTimeout(hideNav, 800)}
                  />
                </LinksWrapper>
                <Call title={data.navCtaTitle} phone={data.navCtaPhone} />
              </>
            )}
            {!matchesLG && (
              <MenuButton onClick={openSidenav} isWhite={!isNavStyled}>
                <Icon size={40} src={menuIcon} />
              </MenuButton>
            )}
          </InnerWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

export default Navigation;
