import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import logo from 'assets/images/logo.svg';

const Wrapper = styled(Link)`
  display: block;
  width: 160px;
  @media (min-width: 1600px) {
    width: 220px;
  }

  ${({ isFooter }) =>
    isFooter &&
    css`
      margin: 0 0 40px 0;

      @media screen and (max-width: 992px) {
        width: 220px;
        margin: 50px 0 10px 0;
      }
    `}
`;

const Brand = styled.img`
  max-width: 100%;
`;

const Logo = ({ isFooter, close }) => (
  <Wrapper isFooter={isFooter} to="/" onClick={() => close !== null && close()}>
    <Brand src={logo} alt="Logo Top Flota" />
  </Wrapper>
);

Logo.propTypes = {
  close: PropTypes.func,
  isFooter: PropTypes.bool,
};

Logo.defaultProps = {
  close: null,
  isFooter: false,
};

export default Logo;
