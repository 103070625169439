import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  position: ${({ noRelative }) => (noRelative ? 'unset' : 'relative')};
  max-width: 1296px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};

  @media screen and (min-width: 991px) {
    padding: 0 40px;
  }

  @media screen and (min-width: 1200px) {
    padding: 0 60px;
  }
`;

const Container = ({ noRelative, fullHeight, children }) => {
  return (
    <Wrapper fullHeight={fullHeight} noRelative={noRelative}>
      {children}
    </Wrapper>
  );
};

Container.defaultProps = {
  fullHeight: false,
  noRelative: false,
};

Container.propTypes = {
  noRelative: PropTypes.bool,
  fullHeight: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Container;
