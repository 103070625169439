import styled, { css } from 'styled-components';

const typographyStyles = css`
  color: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.colors.blueVariant};
  font-weight: ${({ weight }) => weight ?? '400'};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
`;

const headingStyles = css`
  font-weight: 600;
`;

export const HeadingOne = styled.h1`
  ${typographyStyles}
  ${headingStyles}
  font-size: 32px;
  line-height: 1.25;
`;

export const HeadingThree = styled.h4`
  ${typographyStyles}
  ${headingStyles}
font-size: 27px;
  line-height: 1.25;
`;

export const HeadingFour = styled.h4`
  ${typographyStyles}
  ${headingStyles}
font-size: 20px;
  line-height: 1.25;
`;

export const Text = styled.p`
  ${typographyStyles}
  font-size:14px;
  line-height: 1.5;
`;

export const TextLarge = styled.p`
  ${typographyStyles}
  font-size:16px;
  line-height: 1.5;
`;
