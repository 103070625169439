import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Icon from 'components/shared/Icon';
import phoneIcon from 'assets/icons/phone-white.svg';
import phoneIconColor from 'assets/icons/phone-color.svg';

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  background-color: ${({ theme }) => theme.colors.greenPrimary};
`;

const Content = styled.div`
  padding: 15px;
  min-width: 140px;
`;

const Title = styled.p`
  margin: 0 0 1px 0;
  padding: 0;
  font-size: 11px;
  line-height: 1;
  text-transform: uppercase;
`;

const Phone = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
`;

const Wrapper = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.greenVariant};
  color: #fff;
  height: 50px;

  ${({ light }) =>
    light &&
    css`
      background-color: #fff;
      color: ${({ theme }) => theme.colors.greenVariant};
      & ${Badge} {
        background-color: #daedcc;
      }
    `}
`;

const Call = ({ light, title, phone }) => (
  <Wrapper light={light} href={`tel:${phone}`}>
    <Badge>
      {light ? (
        <Icon size={22} src={phoneIconColor} />
      ) : (
        <Icon size={30} src={phoneIcon} />
      )}
    </Badge>
    <Content>
      {title && <Title>{title}</Title>}
      <Phone>{phone}</Phone>
    </Content>
  </Wrapper>
);

Call.defaultProps = {
  title: null,
  light: false,
};

Call.propTypes = {
  light: PropTypes.bool,
  title: PropTypes.string,
  phone: PropTypes.string.isRequired,
};

export default Call;
