import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { BaseCSS } from 'styled-bootstrap-grid';
import PropTypes from 'prop-types';

import GlobalStyle from 'theme/GlobalStyle';
import { theme } from 'theme/mainTheme';

import Navigation from 'components/Navigation/Navigation';
import Footer from 'components/Footer/Footer';

const Wrapper = styled.div`
  position: relative;
`;

const MainLayout = ({ children }) => (
  <ThemeProvider theme={theme}>
     <BaseCSS />
    <GlobalStyle />
    <Navigation />
    <Wrapper>{children}</Wrapper>
    <Footer />
  </ThemeProvider>
);

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default MainLayout;
