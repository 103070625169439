import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Wrapper = styled.nav`
  position: relative;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.colors.blue};

  @media screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Item = styled(Link)`
  margin: 0 0 0 35px;
  padding: 0;
  font-size: 17px;
  font-weight: 500;
  line-height: 1;
  color: ${({ theme }) => theme.colors.light};
  transition: opacity 300ms ease-in-out;
  &:hover {
    opacity: 0.75;
  }

  @media screen and (max-width: 992px) {
    margin: 10px 0;
  }
`;

const Navigation = ({ links }) => {
  return (
    <Wrapper>
      {links &&
        links.map((link, index) => (
          <Item key={index} to={link.url}>
            {link.text}
          </Item>
        ))}
    </Wrapper>
  );
};

Navigation.propTypes = {
  links: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Navigation;
